<template>
  <div>
    <a-modal v-model="modalVisible" title="新增" :width="900" :centered="true" :body-style="modalBodyStyle">
<!--      <a-tabs type="card">-->
<!--        <a-tab-pane key="1" tab="申请信息">-->
      <template slot="footer">
        <a-button @click="modalVisible=false">{{showType=='detail' ? '关闭' : '取消'}}</a-button>
        <a-button v-if="showType=='add' || showType=='edit'" type="primary" @click="confirm('1')">提交</a-button>
        <a-button v-if="showType=='check'" @click="confirm('3')">审核不通过</a-button>
        <a-button v-if="showType=='check'" type="primary" @click="confirm('2')">审核通过</a-button>
      </template>
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="formDatas" :rules="formRules" :label-col="{span:9}" :wrapper-col="{span:14}">
          <h3 style="font-weight: bold; color: #333;text-align: center;font-size: 25px">申请单位基本情况</h3>
          <a-form-model-item label="电梯检测单位名称" prop="depname">
            <a-input v-model.trim="formDatas.depname" />
          </a-form-model-item>
          <a-form-model-item label="单位地址" prop="address">
            <a-input v-model.trim="formDatas.address" />
          </a-form-model-item>
          <a-form-model-item label="社会统一信用代码" prop="creditcode">
            <a-input v-model.trim="formDatas.creditcode" />
          </a-form-model-item>
          <a-form-model-item label="电梯检验师" prop="detector">
            <a-input v-model.trim="formDatas.detector" />
          </a-form-model-item>
          <a-form-model-item label="电梯检验员" prop="detectioner">
            <a-input v-model.trim="formDatas.detectioner" />
          </a-form-model-item>
          <a-form-model-item label="电梯检验检测机构名称" prop="detectioname">
            <a-input v-model.trim="formDatas.detectioname" />
          </a-form-model-item>
          <a-form-model-item label="证书编号" prop="license">
            <a-input v-model.trim="formDatas.license" />
          </a-form-model-item>
          <a-form-model-item label="有效期至" prop="usefulife">
            <a-date-picker v-model="formDatas.usefulife"></a-date-picker>
          </a-form-model-item>
          <a-form-model-item label="试点电梯数量" prop="liftnumber">
            <a-input v-model.trim="formDatas.liftnumber" />
          </a-form-model-item>
          <a-form-model-item label="是否自建电梯检测信息化系统" prop="hasdetection" :label-col="{span:14}" :wrapper-col="{span:10}">
            <a-radio-group v-model="formDatas.hasdetection">
              <a-radio :value="0">是</a-radio>
              <a-radio :value="1">否</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="附件上传" prop="accessoryurl">
            <a-upload name="fileList" :headers="uploadHeaders" :data="uploadData" action="/upload" :file-list="userloginurlList" @change="userloginurlUploaded">
              <a-button> <a-icon type="upload" /> 点击上传文件 </a-button>
            </a-upload>
          </a-form-model-item>
      </a-form-model>
<!--        </a-tab-pane>-->
<!--        <a-tab-pane v-if="" key="2" tab="流程追踪">-->
        <h3 style="font-weight: bold; color: #333;text-align: center;font-size: 25px">流程追踪</h3>
          <a-timeline style="margin-left: 10%">
            <a-timeline-item v-for="(item, index) in wrokflow" :key="index" :color="index<wrokflowInex?'green':'gray'">
              <div>操作步骤: {{liftapplicationsteps[item.flowstep]}}</div>
              <div>操作人员: {{item.person}}</div>
              <div v-if="item.flowstep=='40'">退出原因: {{item.reason}}</div>
              <div>完成时间: {{moment(item.finishtime,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss")}}</div>
            </a-timeline-item>
          </a-timeline>
<!--        </a-tab-pane>-->
<!--      </a-tabs>-->
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import {findWorkflowByorderId} from "A/wbgl";
import liftapplicationsteps from "@/json/liftapplicationsteps";
export default {
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      liftapplicationsteps,
      modalBodyStyle: {
        height: '680px',
        overflowY: 'auto',
      },
      modalVisible: false,
      formDatas: {
        depname:'',
        address:'',
        creditcode:'',
        license:'',
        usefulife:'',
        licenselevel:'',
        applyperson:'',
        applyphone:'',
        bignum:null,
        liftnumber:'',
        insurancebuy:'',
        public:'',
        hasiot:'',
        principal:'',
      },
      //这里面的数据属于必填项
      formRules: {
      },
      userloginurlList: [],
      wrokflow: [
        {
          flowstep:'10',
          person:'超级管理员',
          finishtime:'20211206160650'
        },
        {
          flowstep:'20',
          person:'超级管理员',
          finishtime:'20211206161650'
        }
      ],
      wrokflowInex:20,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['uploadHeaders', 'uploadData']),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    resetDatas(){

    },
    getWorkFlow() {
      let params = {
        maintenancorderid: this.detailData.maintenancorderid
      };
      findWorkflowByorderId(params).then(res => {
        if(res && res.returncode == '0') {
          this.wrokflow = res.item;
          this.getWorkFlowIndex();
        }
      })
    },
    getWorkFlowIndex() {
      if(this.formDatas.processstep && this.wrokflow && this.wrokflow.length) {
        this.wrokflow.forEach((item, index) => {
          if(item.flowstep == this.formDatas.processstep) {
            this.wrokflowInex = index;
          }
        })
      }
    },
    confirm(checkstatus) {
    },
    userloginurlUploaded(info) {
      let fileList = [...info.fileList];

      // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);

      // 2. read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.urlPath;
          // let nameIndex = file.response.urlPath.lastIndexOf('/');
          // if(nameIndex < 0) {
          //   nameIndex = file.response.urlPath.lastIndexOf('\\');
          // }
          // let fileName = file.response.urlPath.substr(nameIndex+1);
          // file.name = fileName
          this.formDatas.userloginurl = file.response.urlPath;
        }
        return file;
      });
      this.userloginurlList = fileList;
    },
  }
}
</script>